import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ProductsHttp } from 'lib-core';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  getProductById,
  getProductByIdError,
  getProductByIdSuccess,
  getProducts,
  getProductsFailure,
  getProductsSuccess,
  setActiveProduct,
  setActiveProductSuccess
} from '../actions/product.actions';
import * as fromProduct from '../models/product.model';
import { GetProductModel, ProductModel } from '../models/product.model';
import { selectProducts } from '../reducers/product.reducer';
import {
  getLandingFilteredProducts,
  getLandingFilteredProductsFailure,
  getLandingFilteredProductsSuccess
} from './../actions/product.actions';
import { CommonService } from '../../services/common.service';
import { AddressService } from '../../services/address.service';

@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productsHttp: ProductsHttp,
    private router: Router,
    private store: Store<fromProduct.ProductModel>,
    private commonService: CommonService,
    private addressService: AddressService
  ) {}

  setActiveProduct = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveProduct),
      catchError(err => {
        this.router.navigate(['/']);
        return of(getProductsFailure(err));
      }),
      mergeMap((data: GetProductModel) => [setActiveProductSuccess(data), getProductById(data)])
    )
  );

  getProductById = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProductById),
      withLatestFrom(this.store.pipe(select(selectProducts))),
      mergeMap(([value, store]) => {
        if (store['ids'].length && store['entities'][value.id] && store['entities'][value.id].fulldata) {
          return of();
        } else {
          return this.productsHttp.getProductDetailsById(value.id, value.slug).pipe(
            map((res: dataInterface) => ({
              ...res.data.product,
              categriesTree: res.data.categriesTree
            }))
          );
        }
      }),
      map((data: fromProduct.ProductModel) => getProductByIdSuccess(data)),
      catchError(() => {
        this.router.navigate(['/']);
        return of(getProductByIdError());
      })
    );
  });

  setProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getProducts),
      withLatestFrom(this.store.pipe(select(selectProducts))),
      mergeMap(([action]) => {
        return this.productsHttp
          .getproductsbyids({
            ids: action.data,
            locationId: this.addressService.getSelectedLocationId() || 1,
            currentDateTime: this.commonService.getCurrentDateGMT4()
          }).pipe(
            map((res: { data: ProductModel[]; hasAgeContentRestriction: boolean }) => {
              if (!res?.data) {
                throw new Error('No data found');
              }
              res.data.forEach(item => {
                item.fullData = false;
                return item;
              });
              if (!action.checkModal) {
                const result: any = { ...res, hasAgeContentRestriction: false };
                return getProductsSuccess(result);
              }

              const result: any = res;
              return getProductsSuccess(result);
            }),
            catchError(err => {
              this.router.navigate(['/']);
              return of(getProductsFailure(err));
            }),
          );
      }),
    )
  );

  setLandingProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(getLandingFilteredProducts),
      withLatestFrom(this.store.pipe(select(selectProducts))),
      filter(([action, store]) => {
        // action.data.productIds = action.data.productIds.filter(function(val) {
        //   return store['ids'].indexOf(val) == -1;
        // });
        return action.data.productIds.length > 0;
      }),
      mergeMap(([action]) => {
        return this.productsHttp.getLandingFilteredProducts(action.data).pipe(
          map((res: ProductModel[]) => {
            res.forEach(item => {
              item.fullData = false;
              return item;
            });
            return res;
          })
        );
      }),
      map(res => {
        return getLandingFilteredProductsSuccess({ filteredLangindProducts: res });
      }),
      catchError(err => {
        this.router.navigate(['/']);
        return of(getLandingFilteredProductsFailure(err));
      })
    )
  );
}

interface dataInterface {
  data: any;
}
